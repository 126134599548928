@tailwind base;
@tailwind components;
@tailwind utilities;

.maplibregl-map, .maplibregl-canvas-container{
    height: 100%;
}

.left-0-i {
    left: 0 !important;
}

.transition-left {
    transition: left .5s ease-in-out;
}

.left-minus-full{
    left: -100%;
}

.horizontal-shake {
    animation: horizontal-shaking 0.35s infinite;
}

.shake {
    animation: shake 0.5s infinite;
}

@keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.spin {
    animation: animate 2s infinite;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(720deg);
    }
}

.label-text {
    line-height: 1.5rem;
}

.styled-select {
    padding: 0;
}
.styled-select > div {
    background: transparent;
    border: none;
    height: 100%;
    color: hsl(var(--bc) / var(--tw-text-opacity));
}

.styled-select input{
    box-shadow: none !important;
}
.styled-select > div > div > div  {
   color: inherit;
}

.styled-select > #react-select-2-listbox > div {
    background-color: hsl(var(--b2) / var(--tw-bg-opacity));
    opacity: 0.9;
}

.styled-select > #react-select-2-placeholder > div input {
    outline: none;
}